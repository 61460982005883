var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Tips "),_c('span',{staticClass:"tag is-large is-primary"},[_vm._v("Total: "+_vm._s(_vm.tips.length))])])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('b-button',{staticClass:"right",attrs:{"outlined":"","type":"is-primary","id":"add-button","icon-left":"plus"},on:{"click":_vm.createNewTip}},[_vm._v("Add")])],1)])]),_c('b-table',{attrs:{"data":_vm.tips,"hoverable":true},on:{"click":_vm.openTip}},[_c('b-table-column',{attrs:{"width":"80","label":"Sticker","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('figure',{staticClass:"m-0 p-0 image"},[(_vm.rowImageSrc(props.row) == null)?_c('img',{staticClass:"thumbnail",attrs:{"src":require("@/assets/listing-placeholder.png")}}):_c('img',{staticClass:"thumbnail",attrs:{"src":_vm.rowImageSrc(props.row)}})])]}}])}),_c('b-table-column',{attrs:{"field":"title","label":"Title","sortable":"","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p',{staticClass:"pt-1 px-3 has-text-weight-bold",style:(_vm.rowStyle(props.row))},[_vm._v(" "+_vm._s(props.row.title)+" ")]),_c('p',{staticClass:"pb-1 px-3 m-0",style:(_vm.rowStyle(props.row)),domProps:{"innerHTML":_vm._s(props.row.body)}})]}}])}),_c('b-table-column',{attrs:{"field":"status","width":"100","label":"Status","sortable":"","searchable":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{class:[
                  'tag',
                  {'is-danger is-light': props.row.status == 'draft'},
                  {'is-warning': props.row.status == 'test'},
                  {'is-info': props.row.status == 'fix'},
                  {'is-success': props.row.status == 'live'}
                ]},[_c('b',[_vm._v(_vm._s(props.row.status))])])]}}])}),_c('b-table-column',{attrs:{"field":"created_at","width":"60","label":"Created","sortable":"","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticClass:"tag is-light"},[_vm._v(" "+_vm._s(new Date(props.row.created_at).toLocaleDateString())+" ")])]}}])}),_c('b-table-column',{attrs:{"field":"id","width":"60","label":"ID","searchable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('p',{staticClass:"is-size-7"},[_vm._v(_vm._s(props.row.id))])]}}])}),_c('b-table-column',{attrs:{"field":"options","width":"20"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"},nativeOn:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"icon-right":"dots-vertical"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.duplicateTip(props.row)}}},[_c('b-icon',{attrs:{"size":"is-small","icon":"content-copy"}}),_vm._v(" Duplicate ")],1),_c('b-dropdown-item',{staticClass:"has-text-danger",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.deleteTip(props.row)}}},[_c('b-icon',{attrs:{"size":"is-small","icon":"delete"}}),_vm._v(" Delete ")],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }