<template>
  <div class="content">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title is-1">Tips <span class="tag is-large is-primary">Total: {{ tips.length }}</span></h1>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-button outlined type="is-primary" class="right" id="add-button" icon-left="plus" @click="createNewTip">Add</b-button>
        </div>
      </div>
    </div>
    <b-table
      :data="tips"
      :hoverable="true"
      @click="openTip"
    >
      <b-table-column width=80 label="Sticker" sortable v-slot="props">
        <figure class="m-0 p-0 image" >
          <img v-if="rowImageSrc(props.row) == null" class="thumbnail"  src="@/assets/listing-placeholder.png">
          <img v-else class="thumbnail" :src="rowImageSrc(props.row)">
        </figure>
      </b-table-column>
      <b-table-column field="title" label="Title" sortable searchable v-slot="props">
        <p class="pt-1 px-3 has-text-weight-bold"
          :style="rowStyle(props.row)">
          {{ props.row.title }}
        </p>
        <p class="pb-1 px-3 m-0" :style="rowStyle(props.row)" v-html="props.row.body"  />
      </b-table-column>
      <b-table-column field="status" width=100 label="Status" sortable searchable centered v-slot="props">
          <span
            :class="[
                    'tag',
                    {'is-danger is-light': props.row.status == 'draft'},
                    {'is-warning': props.row.status == 'test'},
                    {'is-info': props.row.status == 'fix'},
                    {'is-success': props.row.status == 'live'}
                  ]">
              <b>{{ props.row.status }}</b>
          </span>
      </b-table-column>
      <b-table-column field="created_at" width=60 label="Created" sortable centered v-slot="props">
          <span class="tag is-light">
              {{ new Date(props.row.created_at).toLocaleDateString() }}
          </span>
      </b-table-column>
      <b-table-column field="id" width=60 label="ID" searchable v-slot="props">
          <p class="is-size-7">{{ props.row.id }}</p>
      </b-table-column>
      <b-table-column field="options" width=20 v-slot="props">
        <b-dropdown aria-role="list" position="is-bottom-left" @click.native.stop>
            <template #trigger>
                <b-button icon-right="dots-vertical" />
            </template>
            <b-dropdown-item aria-role="listitem" @click="duplicateTip(props.row)">
              <b-icon size="is-small" icon="content-copy"></b-icon>
              Duplicate
            </b-dropdown-item>
            <b-dropdown-item class="has-text-danger" aria-role="listitem" @click="deleteTip(props.row)">
              <b-icon  size="is-small" icon="delete"></b-icon>
              Delete
            </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import db from "@/firebase/db"
import DateFormatter from "@/formatters/DateFormatter"

export default {
  name: 'TipsEncouragementListView',
  data() {
    return {
      tips: []
    }
  },
  firestore: {
    tips: db.collection('tips_encouragements').orderBy("created_at", "desc")
  },
  methods: {
    createNewTip() {
      db.collection("tips_encouragements").add({
          created_at: Date.now(),
          title: "You can do it!",
          status: "draft",
          date: null,
      })
      .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.$router.push({ name: 'TipEncouragementView', params: { id: docRef.id } })
      })
      .catch((error) => {
          console.error("Error adding document: ", error);
      });
    },
    openTip(tip) {
      this.$router.push({ name: 'TipEncouragementView', params: { id: tip.id } })
    },
    deleteTip(tip) {
        console.log(tip)
        this.$buefy.dialog.confirm({
            title: 'Deleting tip',
            message: `Are you sure you want to delete tip: <b><i>"${tip.title}"</i></b>? <br /><br />This action cannot be undone.`,
            confirmText: 'Delete Tip',
            type: 'is-danger',
            hasIcon: true,
            onConfirm: () => {
              db.collection("tips_encouragements").doc(tip.id)
              .delete()
              .then(() => {
                // We don't delete stickers here cause we store them using the sticker name and they can be
                // used across different tips. Saves on storage, yo!
                this.$buefy.toast.open({type: 'is-success', message: `Tip successfully deleted!`})
              }).catch((error) => {
                this.$buefy.toast.open({type: 'is-danger', message: `Error deleting meal plan: ${error}`})
              });
            }
        })
    },
    duplicateTip(tip) {
      db.collection("tips_encouragements").doc(tip.id)
        .get()
        .then((doc) => {
          if (doc.exists && doc.data()) {
            let newData = doc.data();
            newData['created_at'] = Date.now();
            newData['status'] = "draft";
            return db.collection("tips_encouragements").add(newData)
              .then((docRef) => {
                this.$buefy.toast.open({type: 'is-success', message: `Tip successfully duplicated! ${docRef.id}`})
              });
          }
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error duplicating tip ${tip.id}: ${error}`})
        });
    },
    rowStyle(tip) {
      if (tip.background_color == null || tip.foreground_color == null) { return null; }
      return {
        margin: 0,
        backgroundColor: tip.foreground_color.colour,
        color: (tip.foreground_color.invertTheme == "dark" ? "#333333":"#F2F9EE")
      }
    },
    rowImageSrc(tip) {
      if (tip.sticker == null){
        return null;
      }
      return tip.sticker.src;
    },
    formatDate(dateTime) {
      return DateFormatter.displayDate(dateTime);
    }
  }
}

</script>
<style>
.thumbnail{
  height: 100% !important;
  object-fit: cover;
}
</style>
